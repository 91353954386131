import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  color: white;

  .left-container {
    background-color: #9ea5ee;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .right-container {
    background-color: #464fd0;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .left-container, .right-container {
      padding-left: 10%;
  }

  .offer-percent {
    font-size: 80px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 109px;
  }

  .job-offers {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
  }

  .salary-diff-number {
    font-size: 80px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 109px;
  }

`

const SectionDivider = () => {
  return (
    <StyledWrapper className="margin-top-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
              <div className="col-12 left-container ">
            <h1 className="offer-percent">75%</h1>
            <p className="job-offer">
              Students are more likely to get job offers
            </p>
          </div>
          </div>
          <div className="col-12 col-md-6">
              <div className="col-12 right-container ">
            <h1 className="salary-diff-number">4K</h1>
            <p className="job-offer">
              Typical salary difference post college education
            </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default SectionDivider
