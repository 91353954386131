import React from "react";
import SectionDivider from "../../components/Employers/SectionDivider";
import SummaryBlock from "../../components/Home/SummaryBlock";
import axios from "axios";
import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import HeroImages from "../../components/Reusables/HeroImages";
import SEO from "../../components/seo";
import Testimonial from "../../components/Employers/Testimonial";
import LogoSection from "../../components/Home/LogoSection";
import FlatButton from "../../components/Reusables/FlatButton";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import UniversityStudents from "../../components/Students/UniversityStudents";
// import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../../components/Students/EligibilityAndBenefits";
import Accordion from "../../components/Reusables/Accordion";
import LinksOnHover from "../../components/Involved/LinksOnHover";
import Donations from "../../components/Involved/Donations";
import ApplyNowSummary from "../../components/ApplyNow/ApplyNowSummary";
import MyUpreach from "../../components/ApplyNow/MyUpreach";
import hero from "../../images/pageImages/ApplyNow/Hero.jpg";
import upreach from "../../images/pageImages/ApplyNow/myupreach.jpg";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../../components/Reusables/Loader";
const StyledWrapper = styled.div``;

const summary = [
  {
    title: "Ways to get involved",
    description:
      "Champion our work within your organisation and set up synergies with your recruitment, diversity or CSR team. One of our alumnus helped us to secure a Charity of the Year partnership with their graduate employer.",
    image:
      "https://i.picsum.photos/id/101/2621/1747.jpg?hmac=cu15YGotS0gIYdBbR1he5NtBLZAAY6aIY5AbORRAngs",
  },
];

const heroImage = { hero: `${hero}` };
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApplyNow />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/apply-now-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const ApplyNow = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("applyNow", fetchData);
  const { data: heroImage } = useQuery("applyNowImages", fetchHeroImage);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData && newData.showApplySummary && newData.applySummary && (
          <ApplyNowSummary data={newData.applySummary} />
        )}
      </StyledWrapper>
    </Layout>
  );
};
