import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  .heading,
  .subtext,
  .involvedSubtext {
    letter-spacing: ${props => props.theme["zero"]};
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .heading {
    color: ${props => props.theme["primaryColor"]} !important;
    font-size: ${props => props.theme["subHeadingSize"]} !important;
    font-weight: ${props => props.theme["headingBold"]} !important;
    line-height: ${props => props.theme["h2LineHeight"]} !important;

    @media (max-width: 600px) {
      font-size: 15px !important;
      line-height: unset !important;
    }
  }

  .sub-heading-light {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .subtext,
  .involvedSubtext {
    font-size: 20px !important;
    font-size: ${props => (props.for ? "darkred" : "limegreen")};
    font-weight: ${props => props.theme["textBold"]} !important;
    line-height: ${props => props.theme["subTextLineHeight"]} !important;
    padding-left: 2%;
    padding-right: 2%;
    @media (max-width: 600px) {
      font-size: 14px !important;
    }
    color: black;
  }
  .involvedSubtext {
    padding: 0%;
  }
  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 40px;
  }

  .accordion-tab {
    background-color: ${props => props.theme["GreyBackgroundColor"]};
    box-shadow: none;
  }
  .MuiIconButton-label {
    color: #464fd0;
    font-size: 42px;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding-left: 0px !important;
  }

  .accordion-tab {
    border-bottom: 1px solid #c8c8c8 !important;
  }

  h1 {
    @media (max-width: 600px) {
      line-height: 30px;
    }
  }
  .img-class {
    width: 60rem !important;
  }

  .emp-heading {
    text-transform: uppercase;
    line-height: normal !important;
    flex-basis: 100%;
  }

  .react-desc a {
    text-decoration: underline;
    color: black;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions({
  accordion,
  accordionHeading,
  forScreen,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const mainUrl = process.env.GATSBY_API_URL;
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <StyledWrapper
      className={forScreen === "getInvolved" ? "mt-3" : "margin-top-100"}
    >
      <h1 className="padding-left-8 padding-right-8 mb-0">
        {accordionHeading &&
          accordionHeading.heading &&
          accordionHeading.heading}
      </h1>
      <h1 className="padding-left-8 padding-right-8">
        {accordionHeading &&
          accordionHeading.include &&
          accordionHeading.include}
      </h1>
      <div className={classes.root}>
        {accordion &&
          accordion.length > 0 &&
          React.Children.toArray(
            accordion.map((item, index) => (
              <Accordion
                className="accordion-tab padding-left-8 padding-right-8"
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={expanded === index ? "-" : "+"}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography
                    className={classes.heading + " heading emp-heading"}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    className={
                      classes.secondaryHeading + " heading sub-heading-light"
                    }
                  >
                    {item.shortDescription}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    className={
                      classes.secondaryHeading +
                      `${
                        forScreen === "getInvolved"
                          ? " involvedSubtext"
                          : " subtext"
                      }`
                    }
                  >
                    <div className="react-desc">
                      <ReactMarkdown source={item.description} />
                    </div>

                    {item.image !== null ? (
                      item.link === null ? (
                        <img
                          className="img-class"
                          src={mainUrl + item.image.url}
                        />
                      ) : (
                        <a href={item.link}>
                          <img
                            className="img-class"
                            src={mainUrl + item.image.url}
                          />
                        </a>
                      )
                    ) : (
                      <></>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          )}
      </div>
    </StyledWrapper>
  );
}
