import React from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";

const MyUpreach = ({ data, cta }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <div className="container-fluid myupreach">
      <div className="row">
        <div className="col-12 col-lg-6 padding-left-8 padding-right-8 order-2 order-lg-1">
          {data && data.upreachLogo && (
            <img
              className="img-fluid my-4 p-2 myupreach-logo"
              src={mainUrl + data.upreachLogo.url}
              alt=""
            />
          )}
          {data && (
            <ReactMarkdown className="myupreach-list">
              {data.Summary}
            </ReactMarkdown>
          )}
          {cta && (
            <Link className="myupreach-login" to={cta.link} target="_blank">
              <p className="link-upreach">{cta.title}</p>
            </Link>
          )}
        </div>
        <div className="col-12 col-lg-6 order-1 order-lg-2">
          {data && data.Image && (
            <img
              className="img-fluid myupreach-list-img"
              src={mainUrl + data.Image.url}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyUpreach;