import React, { useRef, useEffect } from "react";
import FlatButton from "../Reusables/FlatButton";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { Link } from "gatsby";
import SummaryBlock from "../Home/SummaryBlock";
import donate from "../../images/pageImages/involved/Donate.jpg";
import donatePDF from "../../documents/donate.pdf";
import ReactGA from "react-ga";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  margin-top: 80px;

  .flat-button {
    color: white;
    font-size: 20px !important;
    font-weight: 800 !important;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .donation-text-box > h1 {
    color: black;
  }
  .box-container {
    color: white;
    padding-left: 8%;
    padding-right: 8%;
  }

  .donations-box {
    max-width: 600px;
  }

  .donation-text-box {
    margin-top: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .donation-text-box > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .padding-box {
    padding: 30px 48px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    @media (max-width: 991px) {
      padding: 15px 25px;
    }
  }
`;

const Donations = ({ donationSummary, donationLinks }) => {
  const donateRef = useRef(null);
  useEffect(() => {
    donateRef.current.onclick = function (event) {
      ReactGA.event({
        category: "Get Involved Page",
        action: "Contact the Chief Executive - Click",
      });
    };
  }, []);

  return (
    <StyledWrapper id="donateSummary">
      {/* <SummaryBlock summary={donationSummary} /> */}
      <div className="container-fluid">
        <div className="row box-container donation-text-box">
          <h1>WAYS TO DONATE</h1>
          {donationLinks.length > 0 &&
            React.Children.toArray(
              donationLinks.map(items => (
                <div
                  className={`pt-4 col-12 col-sm-${
                    items.number === 1 ? 12 : 6
                  }`}
                >
                  <div
                    className="col-12 padding-box"
                    style={{ backgroundColor: items.color }}
                  >
                    <div>
                      <h2>{items.title}</h2>
                    </div>
                    <div>
                      <p>
                        <ReactMarkdown>{items.description}</ReactMarkdown>
                      </p>
                    </div>
                    <div>
                      <a
                        href={items.href}
                        target="_blank"
                        ref={items.number === 1 ? donateRef : null}
                      >
                        <span className="flat-button">{items.links}</span>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Donations;
