import React, { useEffect, useRef } from "react";
import FlatButton from "../Reusables/FlatButton";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .home-summary-cta {
    text-decoration: none;
    color: black;
  }

  .mission {
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .subtext a {
    text-decoration: underline;
    color: black;
  }
`;

const desc = val => {
  if (val == null) {
    return "";
  }
  return <ReactMarkdown source={val} />;
};

const SummaryBlock = ({ summary }) => {
  const mainUrl = process.env.GATSBY_API_URL;

  const inputRef = useRef(null);
  useEffect(() => {
    if (summary.title !== undefined) {
      if (summary.title && summary.title.toLowerCase() === "universities") {
        inputRef.current.children[1].onclick = function (event) {
          ReactGA.event({
            category: "Universities Page",
            action: "Get in Touch - Click",
          });
        };
      } else if (summary.title && summary.title.toLowerCase() === "employers") {
        inputRef.current.children[1].onclick = function (event) {
          ReactGA.event({
            category: "Employers Page",
            action: "Get in Touch - Click",
          });
        };
      }
    }
  }, []);
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
        <div className="row mission-row">
          <div className="col-12 col-md-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1">
            <h1>{summary.title}</h1>
            <p className="subtext"> {summary.intro && summary.intro}</p>
            {summary.description && (
              <p className="subtext" ref={inputRef}>
                {desc(summary.description)}
              </p>
            )}
            <a
              target={summary.externalLink ? "_blank" : null}
              className="home-summary-cta"
              href={
                summary.hrefEmail ? `mailto:${summary.hrefEmail}` : summary.href
              }
            >
              <FlatButton>{summary.cta} </FlatButton>
            </a>
            {summary.cta2 && (
              <a
                target="_blank"
                className="home-summary-cta"
                href={
                  summary.hrefEmail2
                    ? `mailto:${summary.hrefEmail2}`
                    : summary.href2
                }
              >
                <FlatButton>{summary.cta2} </FlatButton>
              </a>
            )}
          </div>
          <div className="col-12 col-md-6 order-1 order-md-1">
            {summary.image && (
              <img
                className="img-fluid mx-auto img-size-donate"
                src={mainUrl + summary.image.url}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default SummaryBlock;
